import React from "react";
import { concatClassNames as cn } from "@sys42/utils";

import Layout from "../layouts/layout";

import { HeaderTitle } from "../components/HeaderTitle";
import { HtmlContent } from "../components/HtmlContent";
import { WavySeparator } from "../components/WavySeparator";

import * as helperStyles from "./helpers.module.css";

const Terms = () => (
  <Layout title={"Terms and Conditions"}>
    <article>
      <HeaderTitle overline={"Last updated: June 28, 2022"}>
        General Terms and Conditions ("GTC")
      </HeaderTitle>

      <WavySeparator style={{ paddingTop: "var(--spacing-md)" }} />

      <div className={helperStyles.container}>
        <HtmlContent
          className={cn(helperStyles.spacingMd, helperStyles.widthHtmlContent)}
        >
          <p>
            These General Terms and Conditions ("Terms and Conditions", "GTC")
            govern the contract concluded with Userbrain GmbH ("us", "we" or
            "our") on the website{" "}
            <a href={"https://www.userbrain.com"}>https://www.userbrain.com</a>{" "}
            regarding the utilisation of user tests ("Service(s)").
          </p>
          <p>
            Please read these General Terms and Conditions carefully before
            concluding the contract and/or using the Service.
          </p>
          <p>
            Your access to and use of the Service requires that you accept and
            comply with the present Terms and Conditions. These Terms and
            Conditions apply to all contracting parties, visitors, users and
            others who access or use the Service.
          </p>
          <strong>
            By concluding the contract or accessing or using the Service, you
            agree to these Terms and Conditions. If you do not agree to any part
            of the Terms and Conditions you may not conclude the contract and/or
            access the Service.
          </strong>

          <h2>Subscriptions</h2>
          <p>
            Some parts of the Service will be billed on a subscription basis
            ("Subscription(s)"). Bills will be issued in advance on a recurring
            and regular basis ("Billing Cycle"). The Billing Cycles shall be
            fixed either on a monthly or a yearly basis depending on the type of
            Subscription plan you choose when buying the Subscription.
          </p>
          <p>
            At the end of each billing period your Subscription will
            automatically be renewed under the same Terms and Conditions unless
            you or we cancel the Subscription. You may either cancel your
            Subscription via your online account management service or by
            contacting the Userbrain Customer Support (in this respect see also
            Cancellation below).
          </p>
          <p>
            Depending on the type of Subscription you will be assigned a certain
            number of testers for tests ("Quota") per month or year. As long as
            the contract is in force and effect, you may use and consume an
            unconsumed Quota whenever it suits you.{" "}
            <strong>
              If you cancel your Subscription, you may use and consume your
              remaining Quota before the end of the Subscription term. Once the
              term of the Subscription has ended, any unconsumed Quota shall
              become forfeited irrevocably without any entitlement to
              compensation.{" "}
            </strong>
            This does not apply to Credits that you buy separately in addition
            to a Subscription; such Credits will not become forfeited upon
            cancellation.
          </p>
          <p>
            For processing the payment for your Subscription, a valid method of
            payment, including, without limitation, credit card or PayPal, is
            necessary. You must provide us with correct and accurate billing
            data, such as name, address, state, postal code and phone number,
            and a valid payment method. By forwarding such payment details, you
            automatically authorise us to deduct any Subscription Fees payable
            in connection with your account via those means of payment.
          </p>
          <p>
            If, for any reason, automatic billing should fail, we shall issue an
            electronic bill asking you to manually pay the full amount due for
            the billing period stated on the bill within a certain period.
          </p>

          <h2>Free trial version</h2>
          <p>
            At our sole discretion, we may offer a Subscription with a free
            trial phase for a limited period of time ("Free Trial Phase").
          </p>
          <p>
            You may have to enter your billing data in order to register for the
            free trial version.
          </p>
          <p>
            If you enter your billing data when registering for the Free Trial
            Phase, we will charge you the costs only after the Free Trial Phase
            has ended. In that case, unless you have cancelled your
            Subscription, you will be automatically charged with the respective
            Subscription Fees for the type of Subscription chosen by you on the
            last day of the Free Trial Phase.
          </p>
          <p>
            We reserve the right to (i) change the Terms and Conditions of the
            free trial offer or (ii) discontinue the free trial offer at any
            time.
          </p>

          <h2>Fee Changes</h2>
          <p>
            We may change the fees for the Subscriptions ("Subscription Fees")
            at our sole discretion and at any time. Any change of the
            Subscription Fees shall become effective upon the end of the current
            billing period.
          </p>
          <p>
            We will notify you of any change of the Subscription Fees by giving
            reasonable notice in order to allow you to cancel your Subscription
            before the change will enter into force.
          </p>
          <p>
            Your continued use of the Service after entry into force of the
            change of the Subscription Fee shall be deemed your consent to
            payment of the changed Subscription Fee amount.
          </p>

          <h2>Refunds</h2>
          <p>
            We may examine certain applications for refund for Subscriptions on
            a case-by-case basis and grant them at our sole discretion.
          </p>

          <h2>Content</h2>
          <p>
            Our Service allows you to post, link, save, share or otherwise make
            available certain information, texts, graphics, videos or other
            materials ("Content"). You yourself shall be responsible for the
            Content you upload to the Service, in any area and to any extent,
            from lawfulness to expediency.
          </p>
          <p>
            By uploading Content to the Service you shall grant us the right,
            the licence, and any other necessary rights to use, change, execute,
            display, reproduce and disseminate such Content within and via the
            Service. This does not mean that you shall lose your right to
            Content that you submit, publish or display within or via the
            Service; rather, you shall remain responsible for the lawfulness and
            protection of those rights.
          </p>
          <p>
            You represent and warrant that: (i) you hold the relevant rights to
            the Content (you are the owner of the same or have the right to use
            it and to grant us the rights and licences provided for in these
            Terms and Conditions) and (ii) the publication of your Content
            within or via the Service does not infringe upon the data protection
            rights, publication rights, copyrights, contractual rights or other
            rights of a person or any other laws.
          </p>

          <h2>Accounts</h2>
          <p>
            If you create an account with us, you must provide information to us
            that is correct, complete and up to date. If you fail to do so, this
            will constitute a violation of these Terms and Conditions, which may
            result in immediate termination of your account with our Service.
          </p>
          <p>
            You are responsible for the protection of the password you use for
            accessing the Service, and for any and all activities or actions
            carried out under your password, irrespective of whether your
            password belongs to our Service or to a third-party service.
          </p>
          <p>
            You must not pass on or disclose your password to third parties. You
            must immediately notify us if and when you learn about a breach of
            security or any unauthorised use of your account.
          </p>

          <h2>Secrecy</h2>
          <p>
            During the term of the contractual relationship and for an unlimited
            period of time thereafter, we undertake to keep secret all trade
            secrets and business secrets of the client and in general all
            operational and business matters of the client, and to use them
            exclusively for purposes of ours. Business secrets shall mean all
            business-related facts of a technical or commercial nature which
            become known to us in the course of your work for us, and all
            confidential business and/or operational processes and structures.
          </p>

          <h2>Intellectual Property</h2>
          <p>
            We and our licensors shall retain exclusive title to the services
            and the Service (except for the Content provided by users), features
            and functions. The Service is protected by copyright law, trademark
            law and other laws both in Austria and abroad. Our trademarks and
            brands may not be used in connection with a product or service
            unless we haven given our prior written consent.
          </p>

          <h2>Links to Other Websites</h2>
          <p>
            Our service may contain links to third-party websites or services
            that are not owned or controlled by us.
          </p>
          <p>
            We have no control over the content, privacy policies or practices
            of third-party websites or services and assume no responsibility for
            them. You also acknowledge that we are not responsible or liable,
            neither directly nor indirectly, for any damage or other
            disadvantages caused by or allegedly caused by or related to the use
            of or the reliance on Content, goods or services available on or via
            such websites or services.
          </p>
          <p>
            We strongly recommend that you read the terms and conditions and
            privacy policies of all websites or third-party services you visit.
          </p>

          <h2>Termination</h2>
          <p>
            We may terminate or suspend your account immediately and without
            prior notice or liability, in particular if you violate the present
            Terms and Conditions.
          </p>
          <p>
            You may terminate your account at any time via your Userbrain
            dashboard or by sending a message to{" "}
            <a href={"mailto:support@userbrain.com"}>support@userbrain.com</a>.
            Upon termination, the contract shall be mutually dissolved with
            immediate effect. Even after termination of the contract, you shall
            continue to be obliged to fully comply with all parts of the
            contract which can be reasonably taken to survive the validity of
            the contract and be bound by these Terms and Conditions.
          </p>
          <p>
            After termination you may not be able to fully use certain Services
            of the Platform or access the Platform.
          </p>
          <p>
            <strong>
              If you cancel your Subscription, you may use and consume your
              remaining Quota until the end of the Subscription term. Once the
              term of the Subscription has ended, any unconsumed Quota shall
              become forfeited irrevocably without any entitlement to
              compensation.
            </strong>{" "}
            This shall not apply to Credits that you buy separately in addition
            to a Subscription; such Credits shall not become forfeited upon
            cancellation.
          </p>

          <h2>Limitation of liability</h2>
          <p>
            Neither we nor our directors, employees, partners, representatives,
            suppliers or affiliates are liable for direct or indirect damage,
            accident or consequential damage, including, without limitation,
            lost profit, loss of data, loss of use, reduced goodwill or other
            intangible losses resulting from (i) your access to the Services or
            use of the same or the impossibility of accessing the Services or
            using them; (ii) the behaviour or Content of third parties within
            the Services; (iii) any Content received via the Service; and (iv)
            unauthorised access, use or alteration of your transmissions or
            Content, independent of whether they are based on warranty,
            contract, tort (including negligence) or law, independent of whether
            or not we were informed about the possibility that such damage may
            occur, and even if it turns out that an appeal defined herein has
            failed to achieve its material purpose.
          </p>
          <p>
            We put testers, who are independent and self-employed contractors,
            under an obligation to maintain comprehensive secrecy by them
            agreeing to the GTC (retrievable from{" "}
            <a href={"https://tester.userbrain.com/terms"}>
              https://tester.userbrain.com/terms
            </a>
            ) in Clause 8 as follows: "You agree that all Tests are confidential
            and that you will treat all Tests in the same way you would treat
            your own confidential information. You agree that you will not use,
            disclose or otherwise make known to third parties any Tests,
            recordings, or any associated information, unless such information
            is publicly known or Userbrain has expressly permitted you to do so.
            This obligation shall apply during and, for an unlimited period of
            time, also after the end of the contract term. In the event of a
            violation of the obligation to maintain secrecy, the User undertakes
            to pay a contractual penalty in the agreed lump sum of USD 5,000,
            which shall be irrespective of fault and independent of whether a
            specific damage has been caused or not. Such contractual penalty
            shall become due for payment at the time at which Userbrain obtains
            knowledge of the violation. Additional claims for damages or other
            claims of Userbrain or third parties shall remain unaffected
            thereby. The reasonableness of this contractual penalty is expressly
            recognised and shall be subject to no judicial right of reduction.
            You hereby agree to sign a separate non-disclosure agreement in the
            event that Userbrain or a client of Userbrain asks you to do so."
            For practical reasons, we cannot monitor actual compliance with this
            obligation by the tester and insofar are unable to assume any
            liability therefor. In the event of a non-compliance by the tester,
            the client shall seek direct indemnification from the tester.
          </p>

          <h2>Disclaimer</h2>
          <p>
            We will do our best to recruit suitable testers for the selected
            target group as soon as possible. However, we cannot guarantee that
            we will provide you with testers, especially if you use your own
            selection criteria using the "Screening Feature".
          </p>
          <p>
            You shall use the Service at your own risk. The Service is provided
            without any express or implied warranty, including, without
            limitation, implied warranties of usefulness or fitness for a
            particular purpose, the non-infringement of rights of third parties,
            etc.
          </p>
          <p>
            We, our subsidiaries, affiliates and licensors do not warrant that
            (a) the Service will be available without interruption, reliably or
            at a specific point in time or at a specific place; (b) errors or
            defects will be remedied; (c) the Service will be free from viruses
            or other harmful components; or (d) the results of the use of the
            Service meet your requirements.
          </p>

          <h2>Applicable law; Place of jurisdiction</h2>
          <p>
            These Terms and Conditions shall be governed by the laws of Austria;
            the conflict of laws rules shall be excluded.
          </p>
          <p>
            Any disputes arising out of or in connection with this contract,
            including the issue of valid conclusion, existence or non-existence,
            and any avoidance or challenge of this agreement, shall be
            exclusively settled by the court in Graz, Austria, having
            jurisdiction over the subject matter.
          </p>

          <h2>Terms and Conditions; Changes</h2>
          <p>
            The contract concluded via the website including these Terms and
            Conditions together with the Privacy Policy provided by us
            constitute the entire and exclusive agreement between the parties
            with regard to the subject matter contained herein and replace any
            and all prior or concurrent written or oral agreements or agreements
            regarding its subject matter. Any modification of or amendment to a
            provision of this agreement you might request shall be effective
            only if it is made in writing and signed by a duly authorised
            representative of each party, i.e., if it has been agreed upon with
            us explicitly and in writing and has been duly signed.
          </p>
          <p>
            We may modify or amend these Terms and Conditions at any time. If we
            modify or amend these Terms and Conditions, we will update this page
            and state the date of the last modification or amendment. Prior to
            the modification or amendment, we will inform you by email about the
            same and your response options and their consequences. The
            modification or amendment will enter into force 30 days after the
            notification of the modification or amendment was sent, unless a
            written objection is made before the end of that period, which will
            lead to termination of the contract. Accordingly, if you do not
            object in due time and in the required form, you will agree to the
            modification or amendment. If you object in due time and in the
            required form, the objection will be considered notice of
            termination including the consequences stated under this Clause.
          </p>

          <h2>Contact</h2>
          <p>
            If you have any questions regarding these Terms and Conditions,
            please do not hesitate to contact us at{" "}
            <a href={"mailto:support@userbrain.com"}>support@userbrain.com</a>.
          </p>
        </HtmlContent>
      </div>
    </article>
  </Layout>
);

export default Terms;
